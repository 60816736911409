import { useEffect, useState } from 'react'
import { MessageProps } from '../../components/Toast'
import { firebaseUser } from '../../services/firebase/firebaseAuth'
import { fetchCurrentUser } from '../../services/user'
import { User } from '../types'

const useCurrentUser = (): {
  currentUser: User | null
  isLoading: boolean
  toast: MessageProps
  setToast: React.Dispatch<React.SetStateAction<MessageProps>>
  setDataState: React.Dispatch<
    React.SetStateAction<{
      currentUser: User | null
      isLoading: boolean
    }>
  >
} => {
  const [dataState, setDataState] = useState<{
    currentUser: User | null
    isLoading: boolean
  }>({
    currentUser: null,
    isLoading: true,
  })
  const [toast, setToast] = useState<MessageProps>({ value: '' }) // Handle error message
  const currentFirebaseUser = firebaseUser()

  // Get current user
  useEffect(() => {
    // Create async function to fetch the user and update state
    const getUser = async (): Promise<void> => {
      try {
        const currentUser = await fetchCurrentUser()
        setToast({ value: '' })
        setDataState({
          currentUser,
          isLoading: false,
        })
      } catch (error) {
        if (error.message) {
          setToast({ type: 'error', value: error.message as string })
        } else {
          console.error(error)
        }
      }
    }

    // Call the async function as a side-effect
    getUser()
  }, [currentFirebaseUser])

  return {
    ...dataState,
    setDataState,
    toast,
    setToast,
  }
}

export default useCurrentUser
