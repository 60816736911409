import React from 'react'
import GoogleMapReact from 'google-map-react'
import { MarkerContainer, CustomMarker, AnimatedMarker, mapStyles, MapsContainer } from './styles'

interface GoogleMapsProps {
  coords: {
    lat: number
    lng: number
  }
}

const GoogleMaps = ({ coords }: GoogleMapsProps) => (
  <>
    <MapsContainer>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: `${process.env.REACT_APP_GOOGLE_MAP_API}`,
        }}
        center={coords}
        options={{
          styles: mapStyles,
          scrollwheel: false,
          zoomControl: false,
          fullscreenControl: false,
        }}
        zoom={15}
      >
        <MarkerContainer lat={coords.lat} lng={coords.lng}>
          <CustomMarker />
          <AnimatedMarker />
        </MarkerContainer>
      </GoogleMapReact>
    </MapsContainer>
  </>
)

export default GoogleMaps
