import React from 'react'
import Terms from './components/Terms'
import Privacy from './components/Privacy'

import { DocSection, StyledLink } from './styles'

type PoliciesProps = {
  pathname: string
}

const Policies = ({ pathname }: PoliciesProps) => (
  <DocSection>
    <>
      <StyledLink to={'/'}>{'< Back to Login'}</StyledLink>
      {pathname === '/policies/SS_TOU.DOCX' ? <Terms /> : <Privacy />}
    </>
  </DocSection>
)

export default Policies
