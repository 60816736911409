// import { fleets } from '../config/testData'
import axios from 'axios'
import { firebaseUser } from './firebase/firebaseAuth'
import { RETRIEVE_ALL_FLEETS_API } from './endpoints'
import { Fleet } from '../config/types'
import { createStats } from '../config/hooks/useFleets'

export type RetrieveAllFleets = { data: { data: { fleets: Fleet[] } } }

const fetchFleets = async () => {
  const currentUser = firebaseUser()
  if (currentUser) {
    const token = await currentUser.getIdToken()
    const results = await axios.get(RETRIEVE_ALL_FLEETS_API, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return results as RetrieveAllFleets
  }

  throw new Error('User not found. Please sign in again.')
}

export const fetchSingleFleet = async (fleetUid: string) => {
  const currentUser = firebaseUser()
  if (currentUser) {
    const token = await currentUser.getIdToken()
    const results = await axios.get(`${RETRIEVE_ALL_FLEETS_API}/${fleetUid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const fleet = results.data.data as Fleet
    return createStats([fleet])[0]
  }

  throw new Error('User not found. Please sign in again.')
}

export default fetchFleets
