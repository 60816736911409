import styled, { css } from 'styled-components/macro'
import { VERY_LIGHT_PINK, PRIMARY, DANGER } from '../../config/styles/colours'
import { BORDER_RADIUS } from '../../config/styles/constants'

interface InputStyledProps {
  error?: string
  textArea?: boolean
}

export const InputContainer = styled.div`
  margin-bottom: 12px;
`

export const Label = styled.label<InputStyledProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  background-color: ${VERY_LIGHT_PINK};
  height: ${({ textArea }) => (textArea ? '49px' : 'auto')};
  border-radius: ${BORDER_RADIUS}px;
  border: 2px solid transparent;
  padding: 6px;
  transition: all 0.3s ease-in-out;
  &:focus-within {
    border: 2px solid ${({ error }) => (error ? DANGER : PRIMARY)};
  }
`

const inputStyles = css<InputStyledProps>`
  width: 100%;
  background-color: transparent;
  border: 0;
  outline: 0;
  color: ${({ error }) => (error ? DANGER : 'initial')};
`

export const InputStyled = styled.input<InputStyledProps>`
  ${inputStyles}
`
export const TextAreaStyled = styled.textarea<InputStyledProps>`
  ${inputStyles}
  resize: none;
  min-height: 100px;
`
export const LabelText = styled.p`
  margin-bottom: 2px;
`
export const ErrorText = styled.p`
  margin-bottom: 10px;
  text-align: left;
  margin-top: 12px;
  color: ${DANGER};
`
