/**
 * FLEET
 */
export type Fleet = {
  id: string
  fleetId: string
  location?: string
  createdAt: string
  lastUpdatedAt?: string
  silos?: Array<Silo>
  customer?: Customer
  customerId?: string
  stats: SiloStatsData
}
export type Customer = {
  id: string
  name: string
  customerId: string
  createdAt: string
  lastUpdatedAt?: string
  fleets?: Fleet[]
  silos?: Silo[]
}
// Frontend type only
export type SiloStatsData = {
  healthy: number
  warning: number
  critical: number
  [key: string]: number
}

/**
 * SILO
 */
export type Silo = {
  siloId: string
  thresholdAmber: number
  thresholdRed: number
  siloType?: SiloType
  positionData?: SiloPositionData
  id: string
  createdAt: string
  lastUpdatedAt?: string
  sandData?: SandData
  fleetId?: string
  customerId?: string
  sortOrder: number
}
export type SiloType = {
  model: string
  capacity: number
  id: string
  createdAt: string
  lastUpdatedAt?: string
  [key: string]: number | string | undefined
}
export type SiloPositionData = {
  unitID: number
  modemName?: string
  latitude?: number
  longitude?: number
  mdmid?: string
  alais?: string
}
export type SandData = {
  dataID: number
  currentWeight?: number
  maxWeight?: number
  sandType?: string
  blender?: string
  unitAddress?: string
  FK_mdmid?: string
  locTime?: string
  bits?: string
  payload?: string
  satFix?: string
  noWeightSignal?: number
  errorWeightSignal?: number
  formatErrorWeightSignal?: number
  rowID?: number
  [key: string]: number | string | undefined
}

export enum SandTypeEnum {
  type100 = '100',
  type3050 = '30-50',
  type2040 = '20-40',
  type4070 = '40-70',
  typeUndefined = 'Undefined',
}

/**
 * USER
 */
export type User = {
  id: string
  email: string
  name: string
  role: UserRole
  firebaseUid: string
  createdAt: string
  lastUpdatedAt?: string
  lastLoggedInAt?: string
  arePushNotificationsActivated: boolean
  areEmailNotificationsActivated: boolean
}
export type UserRole = 'SUPER_ADMIN' | 'ADMIN' | 'OPERATOR' | 'EXTERNAL_USER'
