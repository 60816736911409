import React, { RefObject } from 'react'
import { InputStyled, Label, LabelText, ErrorText, InputContainer, TextAreaStyled } from './styles'

interface MainInputProps {
  label?: string
  error?: string
  labelRef?: RefObject<HTMLLabelElement> | null
}

interface InputProps extends React.PropsWithRef<JSX.IntrinsicElements['input']>, MainInputProps {
  textArea?: false
}

interface TextAreaProps extends React.PropsWithRef<JSX.IntrinsicElements['textarea']>, MainInputProps {
  textArea: true
}

const isTextArea = function isTextArea(props: InputProps | TextAreaProps): props is TextAreaProps {
  return Boolean(props.textArea)
}

const Input = React.forwardRef(
  (props: InputProps | TextAreaProps, ref: React.Ref<HTMLInputElement | HTMLTextAreaElement>) => {
    const { label, labelRef = null, error } = props

    return (
      <InputContainer>
        <Label htmlFor={label} error={error} ref={labelRef}>
          {label && <LabelText>{label}</LabelText>}
          {(() => {
            if (isTextArea(props)) {
              const { label, labelRef, error, textArea, ...otherProps } = props
              return (
                <TextAreaStyled ref={ref as React.Ref<HTMLTextAreaElement>} id={label} error={error} {...otherProps} />
              )
            }

            const { label, labelRef, error, textArea, ...otherProps } = props
            return (
              <InputStyled
                ref={ref as React.Ref<HTMLInputElement>}
                id={label}
                error={error}
                type="text"
                {...otherProps}
              />
            )
          })()}
        </Label>
        {error && <ErrorText>{error}</ErrorText>}
      </InputContainer>
    )
  },
)

export default Input
