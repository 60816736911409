import firebaseConfig from './firebaseConfig'

// See doc: https://firebase.google.com/docs/auth/web/email-link-auth
// /!\ url: The deep link to embed and any additional state to be passed along.
// /!\ The link's domain has to be whitelisted in the Firebase Console list of authorized domains, which can be found by going to the Sign-in method tab (Authentication -> Sign-in method).
const actionCodeSettings = {
  url: `${process.env.REACT_APP_ROOT_URL}`,
  handleCodeInApp: true,
}

const sendSignInLinkToEmail = async (email: string) =>
  await firebaseConfig.auth().sendSignInLinkToEmail(email, actionCodeSettings)

const isSignInWithEmailLink = (link: string) => {
  return firebaseConfig.auth().isSignInWithEmailLink(link)
}

const signInWithEmailLink = async (email: string, emailLink: string) => {
  const result = await firebaseConfig.auth().signInWithEmailLink(email, emailLink)
  return result
}

const firebaseSignOut = () => firebaseConfig.auth().signOut()

const firebaseUser = () => firebaseConfig.auth().currentUser

export { firebaseUser, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, firebaseSignOut }
