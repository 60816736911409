import { createContext } from 'react'

type UserContext = {
  user?: any
  loading?: boolean
}

const userContext = createContext<UserContext>({
  user: undefined,
})

export default userContext
