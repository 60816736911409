import styled from 'styled-components'
import { BLACK } from '../../config/styles/colours'
import { Link } from 'react-router-dom'

type ListProps = {
  nopadding?: boolean
  normal?: boolean
  nomarginbot?: boolean
  listType: string
}

export const StyledLink = styled(Link)`
  color: ${BLACK};
  font-weight: bold;
`

export const DocSection = styled.div`
  margin: auto;
  padding: 30px;
  width: 50%;
  text-align: justify;

  p {
    font-size: 13px;
    color: ${BLACK};
  }
`

export const DocTitle = styled.h4`
  text-transform: uppercase;
  text-align: center;
`

export const DocList = styled.ol<ListProps>`
  ${({ nopadding }) => nopadding && 'padding: 0;'}

  li {
    ${({ normal }) => normal && 'font-size: 13px;'};

    margin-bottom: ${({ nomarginbot }) => (nomarginbot ? '0' : '12px')};

    color: rgb(54, 58, 64);
    font-weight: ${({ normal }) => (normal ? 'normal' : 'bold')};
    list-style: ${({ listType }) => (listType ? listType : 'none')};
  }
`

export const DocUpdated = styled.p`
  padding: 20px;
  text-align: center;
  font-weight: bold;
  font-style: italic;
`

export const Intro = styled.p`
  font-weight: bold;
  font-style: italic;
`
