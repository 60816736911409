import { useState, useEffect } from 'react'
import firebaseConfig from '../../services/firebase/firebaseConfig'
import history from '../router'
import { isSignInWithEmailLink, signInWithEmailLink } from '../../services/firebase/firebaseAuth'
import { MessageProps } from '../../components/Toast'

/**
 * Custom hook which monitors the firebase authentication state
 */
const useAuth = () => {
  const [toast, setToast] = useState<MessageProps>({ value: '' })
  const [state, setState] = useState(() => {
    const user = firebaseConfig.auth().currentUser
    return {
      user,
      loading: !user,
    }
  })

  /**
   * This function below is based on the firebase doc and their code snippets
   * See: https://firebase.google.com/docs/auth/web/email-link-auth
   */
  const emailLinkSignIn = async () => {
    const emailLink = window.location.href
    if (isSignInWithEmailLink(emailLink)) {
      let email = window.localStorage.getItem('emailForSignIn')

      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation')
      }

      if (email) {
        try {
          await signInWithEmailLink(email, emailLink)
          window.localStorage.removeItem('emailForSignIn')
        } catch (error) {
          setToast({ value: error.message, type: 'error' })
        }
      }
    }
  }

  useEffect(() => {
    // Listen for auth state changes
    const unsubscribe = firebaseConfig.auth().onAuthStateChanged(user => {
      /**
       * When opening the email link, `onAuthStateChanged` is called twice:
       * once when loading the page with `user: null`, and once after Firebase
       * logs the user in. We should keep the loading state if the user is
       * currently attempting to log in.
       */
      const emailLink = window.location.href
      const isLoggingIn = isSignInWithEmailLink(emailLink)
      setState({ user, loading: isLoggingIn })

      if (!user) history.push('/')
    })

    // Handle firebase sign in with email link
    emailLinkSignIn()

    // Unsubscribe to the listener when unmounting
    return () => unsubscribe()
  }, [])

  return { ...state, toast, setToast }
}

export default useAuth
