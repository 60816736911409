import React, { useEffect, useRef } from 'react'
import * as ReactDOM from 'react-dom'
import { X } from 'react-feather'
import { useSpring, useTransition } from 'react-spring'
import styled from 'styled-components/macro'
import { Z_INDEX } from '../../config/styles/constants'
import { Close, ModalContent, ModalHeader, ModalTitle, Overlay } from './styles'

interface ModalProps {
  status: boolean
  toggleFunction: React.SetStateAction<any>
  closeOnDimmerClick?: boolean
  className?: string
  title?: string
  children?: React.ReactNode
}

const ModalC = ({ className, status, toggleFunction, children, title, closeOnDimmerClick = true }: ModalProps) => {
  const fadeOpacity = useTransition(status, null, {
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const faceTop = useSpring({ marginTop: status ? 0 : -800 })

  // Register event listener to close modal when pressing escape
  useEffect(() => {
    const onPressKey = (evt: KeyboardEvent) => {
      // If pressing escape, close modal
      if (evt.keyCode === 27) {
        toggleFunction(false)
      }
    }

    document.addEventListener('keydown', onPressKey, false)

    return () => document.removeEventListener('keydown', onPressKey, false)
  }, [toggleFunction])

  const modalContainer = useRef(document.querySelector('#modal-container')).current

  if (!modalContainer) {
    console.error(
      'Element #modal-container to mount modal in could not be found, verify that such an element exists in the HTML root file.',
    )
    return null
  }

  return ReactDOM.createPortal(
    fadeOpacity.map(
      ({ item, key, props }) =>
        item && (
          <div key={key} className={className} aria-modal>
            <Overlay style={props} onClick={closeOnDimmerClick ? () => toggleFunction(false) : undefined}></Overlay>
            <ModalContent style={faceTop}>
              {title && (
                <ModalHeader>
                  <ModalTitle>{title}</ModalTitle>
                  <Close simple onClick={() => toggleFunction(false)}>
                    Close <X size={14} />
                  </Close>
                </ModalHeader>
              )}
              {children}
            </ModalContent>
          </div>
        ),
    ),
    modalContainer,
  )
}

const Modal = styled(ModalC)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${Z_INDEX.modal};
`

export default Modal
