import React from 'react'
import { Title, SubTitle } from '../../styles'
import { MetroSpinner } from 'react-spinners-kit'
import { PRIMARY } from '../../../../config/styles/colours'
import { SpinnerContainer } from './styles'

const MagicLink = () => (
  <div>
    <Title>Processing Login</Title>
    <SubTitle>This should only take a second</SubTitle>
    <SpinnerContainer>
      <MetroSpinner color={PRIMARY} size={50} />
    </SpinnerContainer>
  </div>
)

export default MagicLink
