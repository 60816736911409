import React from 'react'
import { LoginContainer, LoginSection, Content, Logo, Title } from '../Login/styles'
import LOGO from '../../assets/images/logo.png'

const NotFound = () => {
  return (
    <LoginContainer>
      <LoginSection>
        <Content>
          <Logo src={LOGO} />
          <Title>404 - Not Found</Title>
        </Content>
      </LoginSection>
    </LoginContainer>
  )
}

export default NotFound
