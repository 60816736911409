import styled from 'styled-components/macro'
import { animated } from 'react-spring'
import { SUCCESS, DANGER, WARNING, LIGHT_GREY, PALE_GREY } from '../../../../config/styles/colours'
import { RESPONSIVE_BREAKPOINTS, Z_INDEX } from '../../../../config/styles/constants'

type StatusNumberTypes = {
  type: 'healthy' | 'warning' | 'critical'
}

const colorSelector = (type: 'healthy' | 'warning' | 'critical') => {
  switch (type) {
    case 'healthy':
      return SUCCESS
    case 'warning':
      return WARNING
    case 'critical':
      return DANGER
    default:
      break
  }
}

export const RefreshContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0 5px 0;

  @media (max-width: ${RESPONSIVE_BREAKPOINTS.small}) {
    justify-content: center;
  }

  p {
    margin: 0 10px 0 0;
  }
`

export const StatsContainer = styled(animated.div)`
  display: flex;
  position: relative;
  z-index: ${Z_INDEX.medium};
  width: calc(100% - 15px);
  padding: 0 5%;
  background-color: ${PALE_GREY};

  @media (max-width: ${RESPONSIVE_BREAKPOINTS.small}) {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`

export const StatsInfoBox = styled(animated.div)`
  position: absolute;
  top: 22px;
  @media (max-width: ${RESPONSIVE_BREAKPOINTS.small}) {
    left: 0;
    right: 0;
    justify-content: center;
  }
`
export const StatusContainer = styled(animated.div)`
  position: absolute;
  top: 100px;
  display: flex;
  flex-direction: row;
  @media (max-width: ${RESPONSIVE_BREAKPOINTS.small}) {
    left: 0;
    right: 0;
    justify-content: center;
  }
`

export const Title = styled(animated.span)`
  font-size: 22px;
  color: #363a40;
  font-weight: bold;
  margin: 0;
`
export const Subtitle = styled(animated.p)`
  font-size: 15px;
`

export const StatusBox = styled.div`
  margin-right: 20px;
  align-items: center;
`

export const StatusNumber = styled.p<StatusNumberTypes>`
  text-align: center;
  font-size: 16px;
  color: ${({ type }) => colorSelector(type)};
`
export const StatusText = styled.p``

export const Separator = styled.div`
  width: 100%;
  align-self: flex-end;
  height: 1px;
  background-color: ${LIGHT_GREY};
`
