import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSpring } from 'react-spring' // useTransition
import useWindowSize from 'react-use/lib/useWindowSize'
import Button from '../../components/Button'
import Card from '../../components/Card'
import { CardFiller } from '../../components/Card/style' // FakeCard
import Modal from '../../components/Modal'
import NavBar from '../../components/NavBar'
import SideBar from '../../components/SideBar'
import Toast from '../../components/Toast'
import useCurrentUser from '../../config/hooks/useCurrentUser'
import useFleets from '../../config/hooks/useFleets'
import { Fleet, Silo } from '../../config/types'
import { firebaseSignOut } from '../../services/firebase/firebaseAuth'
import { fetchSingleFleet } from '../../services/fleets'
import Contact from './components/Contact'
import Stats from './components/Stats'
import { CardList, ContentContainer, HomeContainer, HomeContent, LogoutContainer } from './styles'

const Home = () => {
  // TODO: transitions from useFleets: card animation
  const {
    lastRefresh,
    toast,
    fleets,
    loading,
    fleet,
    silos,
    setToast,
    setFleet,
    setSilos,
    setLastRefresh,
  } = useFleets()

  const [refresh, setRefresh] = useState(false)

  const currentUserState = useCurrentUser()

  // TODO: Fix transitions for the <FakeCard> while loading data /!\ it triggers a warning regarding
  // setState on unmounted component
  // const transitions = useTransition(['1', '2', '3', '4', '5', '6'], item => item, {
  //   from: { transform: 'translate3d(0,80px,0)', opacity: 0 },
  //   enter: { transform: 'translate3d(0,0px,0)', opacity: 1 },
  //   leave: { transform: 'translate3d(0,80px,0)', opacity: 0 },
  //   trail: 580,
  // })

  const [contactToggle, setContactToggle] = useState(false)
  const [logoutToggle, setLogoutToggle] = useState(false)

  // Focus main action button when opening log out modal
  const logoutModalFocusEl = useRef<HTMLButtonElement>(null!)
  useEffect(() => {
    if (
      logoutToggle && // Opening the modal
      logoutModalFocusEl &&
      logoutModalFocusEl.current // Check if reference is ready
    ) {
      logoutModalFocusEl.current.focus()
    }
  }, [logoutToggle, logoutModalFocusEl])

  const { height } = useWindowSize()

  const [{ size }, set] = useSpring(() => ({ size: 0 }))

  const checkReturn = (scrollSize: number) => {
    if (scrollSize >= 80) return 70
    return scrollSize
  }

  const onScroll = useCallback(e => set({ size: checkReturn(e.target.scrollTop) }), [set])

  const showLogout = () => {
    setLogoutToggle(true)
  }

  const showContact = () => setContactToggle(true)

  const handleLogout = () => {
    setLogoutToggle(false)
    firebaseSignOut()
  }

  // Handle menu item selection
  const onClickMenu = (id: string) => {
    const fleetSelected = fleets.find((f: Fleet) => f.id === id)

    if (fleetSelected) {
      setFleet(fleetSelected)
      setSilos(fleetSelected.silos || [])
    }
  }

  const handlePageRefresh = async () => {
    try {
      setRefresh(true)

      const time = new Date()
      const updatedFeet = await fetchSingleFleet(fleet.id)

      setFleet(updatedFeet)
      setSilos(updatedFeet.silos || [])

      setLastRefresh(time.getTime())
      setRefresh(false)
    } catch (error) {
      setToast({ value: 'Fleet is not found', type: 'error' })
    }
  }

  console.log(silos)
  return (
    <>
      <Toast message={toast} action={setToast} />

      <Modal toggleFunction={setContactToggle} status={contactToggle} title="Contact Smart Sand">
        <Contact
          key={
            currentUserState.currentUser
              ? currentUserState.currentUser.areEmailNotificationsActivated.toString()
              : 'no-value'
          }
          {...currentUserState}
        />
      </Modal>

      <Modal toggleFunction={setLogoutToggle} status={logoutToggle} title="Logout Confirmation">
        <LogoutContainer>
          <Button simple onClick={showLogout}>
            Cancel
          </Button>
          <Button
            // Focus button when opening the modal
            ref={logoutModalFocusEl}
            onClick={handleLogout}
          >
            Logout
          </Button>
        </LogoutContainer>
      </Modal>

      <HomeContainer style={{ height, width: '100%' }}>
        <SideBar
          showContact={showContact}
          showLogout={showLogout}
          loading={loading}
          fleets={fleets}
          fleet={fleet}
          onClick={onClickMenu}
        />

        <HomeContent onScroll={onScroll}>
          <NavBar showContact={showContact} showLogout={showLogout} />

          <Stats
            size={size}
            fleet={fleet}
            loading={loading || refresh}
            lastRefresh={lastRefresh}
            onRefresh={handlePageRefresh}
          />

          <CardList>
            {loading || refresh ? (
              <>
                {/* TODO: implement better loading animation
                {transitions.map(({ props, key }) => (
                  <FakeCard key={key} style={props} />
                ))} */}
              </>
            ) : (
              <>
                {silos.length > 0 && (
                  <>
                    {/* TODO: card animation {transitions.map(({ item, props, key }) => (
                      <Card key={key} style={props}>
                        {item}
                      </Card>
                    ))} */}
                    {silos
                      .sort((a, b) => {
                        return a.sortOrder - b.sortOrder
                      })
                      .map((silo: Silo, key: number) => (
                        <Card key={key}>{silo}</Card>
                      ))}
                  </>
                )}
              </>
            )}

            <CardFiller />
            <CardFiller />
            <CardFiller />
          </CardList>

          <ContentContainer>
            {loading || refresh ? (
              <p>Please wait. Fetching data...</p>
            ) : (
              <>{silos && silos.length <= 0 && <p>No silos found.</p>}</>
            )}
          </ContentContainer>
        </HomeContent>
      </HomeContainer>
    </>
  )
}

export default Home
