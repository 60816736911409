import React from 'react'
import { Link } from 'react-router-dom'

import { DocTitle, DocList, DocUpdated } from '../../styles'

const Privacy = () => {
  return (
    <div>
      <DocTitle>privacy policy</DocTitle>

      <p>
        This Privacy Policy is designed to assist you in understanding how Smart Sand, Inc. (“Smart Sand”, “we”, “our”,
        or “us”) collects, uses, and safeguards the information you provide to us in using our Mobile Application Mine
        to Wellsite Solutions and our Online Portal (<Link to="/">www.smartsystemtracker.com</Link>) (together, the
        “Services”).{' '}
      </p>

      <DocList listType="upper-roman" nopadding>
        {/* PART 1 */}
        <li>INFORMATION WE COLLECT</li>
        <section>
          <p>
            Through your use of the Mobile Application and the Online Portal, we may collect personal information, which
            is information that identifies you as an individual, relates to you as an identifiable individual or, that
            along with other information, could identify you (collectively, “Personal Information”). We collect the
            following types of Personal Information when you use the Services:
          </p>

          <DocList listType="lower-roman">
            {/* Nested - PART 1 */}
            <li>Information you provide to us.</li>
            <p>
              We need to collect Personal Information if you choose to engage in certain activities on our Mobile
              Application or the Online Portal. Specifically, we collect Personal Information from you when you:
            </p>

            <DocList listType="square" normal>
              <li>
                <u>Create an account.</u> When you create a Smart Sand account through our Mobile Application, you will
                need to provide your name and email address. When you create a Smart Sand account through our Online
                Portal, you will need to provide your name, email address, and company.
              </li>
              <li>
                <u>Access and Use of the Services.</u> When you contact us through the Mobile Application or the Online
                Portal, you will need to provide your name and email address.
              </li>
            </DocList>

            {/* Nested - PART 2 */}
            <li>Information collected automatically.</li>
            <p>
              In addition to the above, we automatically collect the following types of Personal Information as you use
              our Services:
            </p>

            <DocList listType="square" normal>
              <li>
                <u>Usage Information.</u> When you access the Services, the frequency of access, crash logs, and usage
                statistics.
              </li>
              <li>
                <u>Location Information.</u> Information about your location, which may be determined through your IP
                address.
              </li>
              <li>
                <u>Device Information.</u> Information about the device you are using, such as mobile device ID and
                operating system.
              </li>
            </DocList>

            {/* Nested - PART 3 */}
            <li>First-Party Cookies and Application-Instance Identifiers</li>
            <p>
              Cookies and application-instance identifiers are a small file placed on your web browser or mobile device
              that uniquely identify your browser. We use cookies and identifiers on our Online Portal and Mobile
              Application to help us manage and report on your interaction with the Services. Through these cookies and
              identifiers, we are able to collect information that we use to improve the Online Portal, Mobile
              Application, and the Services, track usage and traffic patterns, authenticate login credentials, prevent
              fraudulent activity, and improve the security of the Services.
            </p>

            {/* Nested - PART 4 */}
            <li>Third Party Cookies and Other Technologies</li>
            <p>
              Our third party service providers may also place or recognize a cookie on your web browser or mobile
              device. We use the data collected by these third party service providers to help us track and analyze
              Online Portal and Mobile Application data and usage. We currently utilize the following third party
              service providers for these purposes:
            </p>

            <DocList listType="square" normal>
              <li>
                <u>
                  <b>Google Analytics for Firebase.</b>
                </u>{' '}
                We use a tool called Google Analytics for Firebase in order to derive application behavioral analytics
                and to provide insight on Services usage and user engagement. We use this information to see how users
                interact with our Services and to improve the Services. In order to collect this information, Google
                Analytics for Firebase may set cookies and similar technologies to collect information from your web
                browser or mobile device. This information may include your IP address, how often the Services are used,
                when and for how long you access the Services, your browser or device’s operating system details, your
                device model, version of the Mobile Application, and other technical and statistical information. We do
                not combine the information collected through the use of Google Analytics for Firebase with personally
                identifiable information. For more information about the data collected through Google Analytics for
                Firebase, and how this data is used, please review Firebase for Google’s{' '}
                <a href="https://firebase.google.com/support/privacy" target="_blank" rel="noopener noreferrer">
                  privacy policy
                </a>
                .
              </li>
              <li>
                <u>
                  <b>Firebase Crashlytics.</b>
                </u>{' '}
                We also use a tool called Firebase Crashlytics by Google to track and prevent Online Portal and Mobile
                Application crashes and malfunctions. In case of a crash, a crash report is automatically generated and
                transmitted to Firebase Crashlytics that consists of including the type of device used, the operating
                system, carrier, network information, IP address, signal strength, battery level and batter-charging
                state, Mobile Application version, and other technical information. The information collected by
                Firebase Crashlytics does not personally identify users of the Services and is not associated with other
                data available to Google. For more information about the data collected through Firebase Crashlytics,
                and how this data is used, please review Firebase for Google’s{' '}
                <a href="https://firebase.google.com/support/privacy" target="_blank" rel="noopener noreferrer">
                  privacy policy
                </a>
                .
              </li>
              <li>
                <u>
                  <b>Firebase Performance Monitoring.</b>
                </u>{' '}
                We use a tool called Firebase Performance Monitoring by Google to gain insight into the performance of
                the Services by collecting performance data for use to review and analyze so that we may optimize the
                functionality of the Services. Firebase Performance Monitoring uses cookies and application instance
                identifiers to collect performance data, including the type of device used, the operating system,
                carrier, network information, IP address, signal strength, battery level and batter-charging state,
                Mobile Application version, and other technical information. We do not combine the information collected
                through Firebase Performance Monitoring with personally identifiable information. For more information
                about the data collected through Firebase Performance Monitoring, please review Firebase for Google’s{' '}
                <a href="https://firebase.google.com/support/privacy" target="_blank" rel="noopener noreferrer">
                  privacy policy
                </a>
                .
              </li>
              <li>
                <u>
                  <b>Firebase Authentication.</b>
                </u>{' '}
                We use a tool called Firebase Authentication by Google to authenticate users who access and use the
                Services. Firebase Authentication deposits an access token on your web browser or mobile device that
                uniquely identifies you and collects your IP address. We use this information to verify you and prevent
                fraudulent use of your account. For more information about the data collected through Firebase
                Authentication, please review Firebase for Google’s{' '}
                <a href="https://firebase.google.com/support/privacy" target="_blank" rel="noopener noreferrer">
                  privacy policy
                </a>
                .
              </li>
              <li>
                <u>
                  <b>Mailgun.</b>
                </u>{' '}
                We use a tool called Mailgun to answer requests you send and to send email notifications to you (if such
                notifications are enabled in settings). Mailgun uses pixels to track how you interact with the email and
                collect performance data about our emails, such as whether you opened the email, your mailbox provider,
                email send failures, what time of day you open the email, and your location as determined through your
                IP address. We use this information to improve the performance of our email communications with you. For
                more information about the data collected through Mailgun, please review Mailgun’s{' '}
                <a href="https://www.mailgun.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                  privacy policy
                </a>
                .
              </li>
            </DocList>
          </DocList>
        </section>

        {/* PART 2 */}
        <li>HOW WE USE YOUR INFORMATION</li>
        <section>
          <p>
            We use your Personal Information to provide the Services to you, to improve the Services, and to protect our
            legal rights. We limit the collection of Personal Information to that which is relevant for these purposes.
            We will not store, access, transmit, retain, or otherwise use Personal Information in any way that is
            incompatible with the purposes for which it has been collected or stored as described in this Privacy Policy
            without your consent. Specifically, we may use the Personal Information we collect to:
          </p>

          <DocList listType="square" normal nomarginbot>
            <li>Provide you with the Services.</li>
            <li>Send email and push notifications (if enabled by you) to support your use of the Services.</li>
            <li>Create your Smart Sand account. </li>
            <li>Communicate with you about the Services or to inform you of any changes to our Services.</li>
            <li>Provide user support.</li>
            <li>Maintain and improve our Services. </li>
            <li>Defend our rights and the rights of others.</li>
            <li>Efficiently maintain our business.</li>
            <li>Comply with applicable law.</li>
          </DocList>
        </section>

        <br />

        {/* PART 3 */}
        <li>HOW WE SHARE YOUR INFORMATION</li>
        <section>
          <p>
            We share your Personal Information with service providers that facilitate the provision of our Services, as
            necessary to efficiently run our business, and to comply with our legal and other obligations.
          </p>

          <DocList listType="square" normal>
            <li>
              <u>Service Providers.</u> We share Personal Information with service providers that help us operate and
              provide the Services.
            </li>

            <li>
              <u>Corporate Structure.</u> We share Personal Information within our corporate family, such as with
              subsidiaries, joint ventures, or affiliates, in order to efficiently carry out our business and to the
              extent permitted by law. We would also share Personal Information if we are involved or intend to be
              involved in a merger, acquisition, consolidation, change of control, or sale of all or a portion of our
              assets or in connection with a bankruptcy or liquidation proceeding.
            </li>

            <li>
              <u>To Prevent Harm.</u> We will share Personal Information if we believe it is necessary to detect,
              investigate, prevent, or take action against illegal activities, fraud, or situations involving potential
              threats to the rights, property, or personal safety of any person.
            </li>

            <li>
              <u>Legal Purposes.</u> We will share Personal Information where we are legally required to do so, such as
              in response to court orders, law enforcement or legal process, including for national security purposes;
              to establish, protect, or exercise our legal rights, as required to enforce our terms of service or other
              contracts; to defend against legal claims or demands; or to comply with the requirements of any applicable
              law.
            </li>

            <li>
              <u>With Your Consent.</u> Apart from the reasons identified above, we may request your permission to share
              your Personal Information for a specific purpose. We will notify you and request consent before you
              provide the Personal Information or before the Personal Information you have already provided is shared
              for such purpose.{' '}
            </li>
          </DocList>
        </section>

        {/* PART 4 */}
        <li>CHOICES ABOUT YOUR INFORMATION</li>
        <section>
          <DocList listType="square" normal>
            <li>
              Correct, view, or delete your information. To correct, view, or delete certain Personal Information
              relating to you in our possession, such as name and email address, you must contact a Smart Sand
              administrator. You can do so by clicking on the “Contact Smart Sand” option in the Mobile Application or
              Online Portal. If you request that we delete your information, you will no longer be able to access the
              Services.
            </li>

            <li>
              Cookies. If you would like to opt out of accepting cookies altogether, you can generally set your browser
              to not accept cookies or to notify you when you are sent a cookie, giving you the chance to decide whether
              or not to accept it. You can learn more information about how to reject and delete cookies at{' '}
              <a href="https://www.aboutcookies.org/" target="_blank" rel="noopener noreferrer">
                https://www.aboutcookies.org/
              </a>
              .
            </li>

            <li>
              Google Analytics for Firebase. See http://www.google.com/policies/privacy/partners/ for information about
              how Google uses the information provided to Google Analytics. You can control the information provided to
              Google and opt out of certain ads provided by Google by using any of the methods set forth here or using
              the Google Analytics opt out browser add-on{' '}
              <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">
                here
              </a>
              .
            </li>
          </DocList>
        </section>

        {/* PART 5 */}
        <li>DO NOT TRACK DISCLOSURE</li>
        <p>
          We take no action in response to “Do Not Track” requests received from users’ web browser or mobile devices.
          You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser or
          mobile device. For further details, visit{' '}
          <a href="https://allaboutdnt.com/" target="_blank" rel="noopener noreferrer">
            https://allaboutdnt.com/
          </a>
          .
        </p>

        {/* PART 6 */}
        <li>SECURITY</li>
        <p>
          We maintain commercially reasonable security measures to protect the Personal Information we collect and store
          from loss, misuse, destruction, or unauthorized access. Our security includes industry-standard physical,
          administrative, and technology-based measures. However, no security measure or modality of data transmission
          over the Internet is 100% secure. While we strive to use commercially acceptable means to protect your
          Personal Information, we cannot guarantee absolute security.{' '}
        </p>

        {/* PART 7 */}
        <li>CHILDREN’S PRIVACY</li>
        <p>
          The Services are not intended for individuals under the age of eighteen (18) years. Accordingly, we do not
          knowingly collect or share Personal Information from children.{' '}
        </p>

        {/* PART 8 */}
        <li>THIRD PARTY LINKS</li>
        <p>
          The Mobile Application or the Online Portal may contain links that will let you leave the Mobile Application
          or the Online Portal and access the Smart Sand website. This Privacy Policy applies solely to Personal
          Information that is acquired on the Mobile Application or the Online Portal and not on the website.
        </p>

        {/* PART 9 */}
        <li>CHANGES TO THIS PRIVACY POLICY</li>
        <p>
          This privacy policy may be updated and changed from time to time. We will post the new policy on this page and
          will indicate the date it goes into effect. If the changes allow us to use personally identifying information
          in ways that are different from the ways specified at the time the information was originally collected, our
          website will contain a special notice about the changes and, if required by law, would prompt you for your
          consent for certain changes. Each version of this privacy policy will be identified by its effective date,
          which you can find at the bottom of the page.
        </p>

        {/* PART 10 */}
        <li>CONTACT</li>
        <p>
          If you have any questions or concerns regarding this Privacy Policy, please contact us at
          contact@smartsand.com.
        </p>
      </DocList>

      <DocUpdated>This Privacy Policy was last modified on November 22, 2019.</DocUpdated>
    </div>
  )
}

export default Privacy
