import styled, { css } from 'styled-components/macro'
import { RESPONSIVE_BREAKPOINTS } from '../../config/styles/constants'
import { animated } from 'react-spring'
import { BLACK, LIGHT_GREY } from '../../config/styles/colours'

type FakeCardType = {
  auto?: boolean
}

const textStyle = css`
  color: ${BLACK};
  font-weight: 600;
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
`

export const Header = styled.span<{time?: string}>`
  color: ${({ time }) => time ? `${BLACK}`: 'transparent'};
  font-weight: 600;
  font-size: 12px;
`

export const CardContainer = styled(animated.li)`
  width: 100%;
  max-width: 343px;
  background-color: white;
  margin: 12px;
  border-radius: 6px;
  box-shadow: 3px 3px 14px 0px rgba(0, 0, 0, 0.2);
  @media (max-width: ${RESPONSIVE_BREAKPOINTS.small}) {
    width: 90%;
    max-width: 435px;
    margin-right: 0;
    margin-left: 0;
  }
`
export const Stats = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 20px;
`

export const Group = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
`

export const Item = styled.div`
  flex: 1;
  margin: 5px;
`

export const SandIconBox = styled.div`
  display: flex;
`
export const ItemContent = styled.p`
  ${textStyle}
`
export const Percentage = styled.p`
  ${textStyle}
  margin: 5px 0;
`
export const SandIcon = styled.img`
  height: 12px;
  margin-right: 12px;
`
export const Graphs = styled.div`
  display: flex;
`

export const Cap = styled.p`
  margin: 0;
  margin-top: 12px;
`

export const SiloContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 5px;
`
export const CardFiller = styled.li`
  width: 343px;
  margin: 12px;
  &:last-of-type {
    margin-bottom: 50px;
  }
`
export const FakeCard = styled(CardContainer)<FakeCardType>`
  height: ${({ auto }) => (auto ? 'auto' : '526px')};
  background-color: ${LIGHT_GREY};
  opacity: 0.5;
`
