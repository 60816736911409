/**
 * Round number when needed with maximum 2 decimals
 * @param num - given number to round
 */
export const roundNumber = (num: number) => Math.round(num * 100) / 100

export const roundUp = (num: number, precision: number) => {
  const mathPowPrecision = Math.pow(10, precision)
  return Math.ceil(num * mathPowPrecision) / mathPowPrecision
}
