import React, { useState } from 'react'
import { MetroSpinner } from 'react-spinners-kit'
import LOGO from '../../assets/images/logo_long.png'
import { PRIMARY } from '../../config/styles/colours'
import { Fleet } from '../../config/types'
import PopupMask from '../PopupMask'
import {
  BurgerMenu,
  CloseMenu,
  Container,
  FleetList,
  FleetMenu,
  FleetSubtitle,
  FleetTile,
  LogoContainer,
  PhoneNav,
  SideBarButton,
  SideBarContainer,
  SideLogo,
  SilosQuantity,
} from './styles'

interface SideBarProps {
  showContact: React.SetStateAction<any>
  showLogout: React.SetStateAction<any>
  loading?: boolean
  onClick: (id: string) => void
  fleets: Array<Fleet>
  fleet?: Fleet // Fleet selected
}

const SideBar = ({ showContact, showLogout, loading = false, onClick, fleets, fleet }: SideBarProps) => {
  const [status, setStatus] = useState(false)

  const closeMenu = () => {
    setStatus(false)
  }
  const openMenu = () => {
    setStatus(true)
  }

  return (
    <>
      {status ? (
        <>
          <PopupMask onClick={closeMenu} />
          <CloseMenu size={30} onClick={closeMenu} />
        </>
      ) : (
        <BurgerMenu size={30} onClick={openMenu} />
      )}

      <SideBarContainer style={{ marginLeft: status ? 0 : -230 }}>
        <LogoContainer>
          <SideLogo src={LOGO} />
        </LogoContainer>
        <FleetList empty={!fleets || fleets.length <= 0}>
          {loading ? (
            <Container>
              <MetroSpinner color={PRIMARY} size={50} />
            </Container>
          ) : (
            <>
              {fleets && fleets.length > 0 ? (
                fleets.map(({ id, fleetId, location = '', silos }: Fleet) => (
                  <FleetMenu onClick={() => onClick(id)} key={id} selected={fleet && fleet.id === id} simple>
                    <div>
                      <FleetTile>{fleetId}</FleetTile>
                      <FleetSubtitle>{location}</FleetSubtitle>
                    </div>
                    <SilosQuantity>{silos ? silos.length : 0} Silos</SilosQuantity>
                  </FleetMenu>
                ))
              ) : (
                <Container>
                  <p>No fleets found.</p>
                </Container>
              )}
            </>
          )}
        </FleetList>
        <PhoneNav>
          <SideBarButton simple onClick={showContact}>
            Contact
          </SideBarButton>
          <SideBarButton simple onClick={showLogout}>
            Logout
          </SideBarButton>
        </PhoneNav>
      </SideBarContainer>
    </>
  )
}

export default SideBar
