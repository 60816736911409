import { addHours, formatDistanceToNow } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'

/**
 * Checks if DST is active for current date
 */
export const isDST = () => {
  const today = new Date()

  const jan = new Date(today.getFullYear(), 0, 1).getTimezoneOffset()
  const jul = new Date(today.getFullYear(), 6, 1).getTimezoneOffset()

  return Math.max(jan, jul) !== today.getTimezoneOffset()
}

/**
 * Convert DST based date to 0 GMT Time
 * @param date - date at -4 or-5 GMT based on DST
 */
export const DateGMTFormatter = (date: string) => {
  const offsetHours = isDST() ? 4 : 5

  const updateTimeGMT = addHours(
    new Date(date),
    offsetHours
  ).toUTCString()

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  // Converts 0 GMT time to local time zone
  const utcDate = zonedTimeToUtc(new Date(updateTimeGMT), timeZone)

  return formatDistanceToNow(utcDate)
}
