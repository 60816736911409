import axios from 'axios'
import { SEND_EMAIL } from './endpoints'
import { firebaseUser } from './firebase/firebaseAuth'

/**
 * Sends a contact email.
 * @param message The message of the email.
 */
export const sendContactEmail = async (message: string): Promise<void> => {
  const currentUser = firebaseUser()
  if (!currentUser) {
    throw new Error('No user logged in found, not sending email')
  }

  try {
    const token = await currentUser.getIdToken()
    await axios.post(
      SEND_EMAIL,
      {
        message,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
  } catch (error) {
    console.error(error)
    throw new Error('Something went wrong sending the email. Please try again later.')
  }
}
