import React from 'react'
import { Controller, SwitchStyled } from './styled'
import Button from '../Button'
import { PRIMARY } from '../../config/styles/colours'
import { useSpring } from 'react-spring'

interface SwitchProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  status: boolean
}

const Switch = ({ status, ...otherProps }: SwitchProps) => {
  const position = useSpring({
    left: status ? 26 : -1,
    boxShadow: status ? '2px 2px 4px 0px rgba(0, 0, 0, 0.2)' : '-2px 2px 4px 0px rgba(0, 0, 0, 0.2)',
  })
  const backgroundColor = useSpring({
    backgroundColor: status ? PRIMARY : 'white',
  })

  return (
    <Button simple noFocus {...otherProps}>
      <SwitchStyled style={backgroundColor}>
        <Controller style={position} />
      </SwitchStyled>
    </Button>
  )
}

export default Switch
