import React from 'react'
import { MetroSpinner } from 'react-spinners-kit'
import { ButtonStyled } from './styles'

interface ButtonProps extends React.PropsWithRef<JSX.IntrinsicElements['button']> {
  loading?: boolean
  simple?: boolean
  noFocus?: boolean
}

const Button = React.forwardRef(
  ({ loading, children, simple, noFocus, ...otherProps }: ButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    return (
      <ButtonStyled ref={ref} simple={simple} noFocus={noFocus} {...otherProps}>
        {loading ? <MetroSpinner color="white" size={20} /> : children}
      </ButtonStyled>
    )
  },
)

export default Button
