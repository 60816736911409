import React, { useEffect } from 'react'
import { ToastContainer, Message, Timer } from './styles'
import { AlertCircle, AlertOctagon, CheckCircle } from 'react-feather'
import { useTransition, useSpring } from 'react-spring'

type ToastProps = {
  action: React.Dispatch<any>
  message: MessageProps
}

export type MessageProps = {
  value?: string
  type?: 'success' | 'error' | 'info'
}

const Toast = ({ message: { value = '', type = 'success' }, action }: ToastProps) => {
  const fade = useTransition(value, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })
  const timer = useSpring({
    from: { width: '100%' },
    to: { width: '0%' },
  })

  useEffect(() => {
    const timeOut = setTimeout(() => {
      action({ type, value: '' })
    }, 3000)
    return () => clearTimeout(timeOut)
  }, [action, type])

  let icon: React.ReactNode
  switch (type) {
    case 'error':
      icon = <AlertOctagon color="white" />
      break
    case 'info':
      icon = <AlertCircle color="white" />
      break
    default:
      icon = <CheckCircle color="white" />
      break
  }

  return (
    <>
      {fade.map(
        ({ item, key, props }) =>
          item && (
            <ToastContainer key={key} type={type} style={props}>
              {icon}
              <Message>{value}</Message>
              <Timer style={timer} />
            </ToastContainer>
          ),
      )}
    </>
  )
}

export default Toast
