import React from 'react'
import {
  StatsContainer,
  StatusBox,
  StatusNumber,
  StatusText,
  StatusContainer,
  StatsInfoBox,
  Title,
  Separator,
  Subtitle,
  RefreshContainer,
} from './styles'
import { RefreshCw } from 'react-feather'
import { OpaqueInterpolation } from 'react-spring'
import { SiloStatsData, Fleet } from '../../../../config/types'
import { TooltipIcon, TooltipHover } from '../../../../components/Tooltip/styles'
import { PRIMARY } from '../../../../config/styles/colours'
import Tooltip from '../../../../components/Tooltip'

interface StatsProps {
  size: OpaqueInterpolation<number>
  fleet: Fleet
  loading: boolean
  lastRefresh: number
  onRefresh?: () => void
}

const Stats = ({ size, loading, fleet, lastRefresh, onRefresh }: StatsProps) => {
  const sandData = fleet && fleet.silos && fleet.silos.map(s => s.sandData)

  const minHeight = size.interpolate({
    range: [0, 100],
    output: [180, 50],
  })

  const animatedTitle = size.interpolate({
    range: [0, 70],
    output: [22, -45],
  })
  const animatedTitleSize = size.interpolate({
    range: [0, 70],
    output: [22, 17],
  })

  const animatedSubtitleSize = size.interpolate({
    range: [0, 70],
    output: [15, 11],
  })

  const animatedStatsTop = size.interpolate({
    range: [0, 70],
    output: [100, 22],
  })

  const getStats = (name: string) => {
    if (fleet) {
      const { stats } = fleet
      if (stats) return (stats as SiloStatsData)[name]
    }

    return '-'
  }

  const getLastRefreshDiff = () => {
    const time = new Date()
    const totalMili = time.getTime() - lastRefresh
    const min = Math.floor((totalMili / 1000 / 60) << 0)

    switch (true) {
      case min <= 0:
        return 'Less than a minute ago'
      case min >= 60:
        return 'More than an hour ago'
      case min > 1:
        return `${min} minutes ago`
      default:
        return `${min} minute ago`
    }
  }

  return (
    <StatsContainer style={{ minHeight }}>
      {!loading && (
        <>
          <StatsInfoBox style={{ top: animatedTitle }}>
            <TooltipHover>
              <Title style={{ fontSize: animatedTitleSize }}>{fleet ? fleet.fleetId : ''}</Title>
              <TooltipIcon size="15" color={PRIMARY} />
            </TooltipHover>
            <Tooltip sandData={sandData} size="large" />

            <RefreshContainer>
              <Subtitle style={{ fontSize: animatedSubtitleSize }}>{getLastRefreshDiff()}</Subtitle>
              {onRefresh && <RefreshCw size={16} color={PRIMARY} onClick={onRefresh} />}
            </RefreshContainer>
          </StatsInfoBox>
          <StatusContainer style={{ top: animatedStatsTop }}>
            <StatusBox>
              <StatusNumber type="healthy">{getStats('healthy')}</StatusNumber>
              <StatusText>Healthy</StatusText>
            </StatusBox>
            <StatusBox>
              <StatusNumber type="warning">{getStats('warning')}</StatusNumber>
              <StatusText>Warning</StatusText>
            </StatusBox>
            <StatusBox>
              <StatusNumber type="critical">{getStats('critical')}</StatusNumber>
              <StatusText>Critical</StatusText>
            </StatusBox>
          </StatusContainer>
          <Separator />
        </>
      )}
    </StatsContainer>
  )
}

export default Stats
