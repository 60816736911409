import * as React from 'react'

import { TooltipPopup, TooltipTable, TooltipTableHeader, TooltipTableColumn, PopupSize } from './styles'
import { SiloType, SandTypeEnum, SandData } from '../../config/types'
import { SandIcon } from '../Card/style'
import { getSandTypeIcon } from '../Card'
import { roundUp } from '../../config/utils/number'

type ToolTipProps = {
  siloType?: SiloType
  sandData?: (SandData | undefined)[]
  size?: PopupSize
}

/**
 * Show in a tooltip all weights by silo type for a given capacity
 * @param siloType - object SiloType
 * @param sandData - array of SandData
 * @param size - popup width: large, medium, small or auto
 */
const Tooltip = ({ siloType, sandData, size }: ToolTipProps) => {
  const siloTypeValue = (sandType: string) => {
    const capacity = (siloType && siloType.capacity) || 0
    let result: number = 0

    switch (sandType) {
      case SandTypeEnum.type100:
        result = 91.7 * capacity
        break
      case SandTypeEnum.type3050:
        result = 95.5 * capacity
        break
      case SandTypeEnum.type2040:
        result = 97.3 * capacity
        break
      case SandTypeEnum.type4070:
        result = 94.2 * capacity
        break
      default:
        result = 98 * capacity
    }

    return `${roundUp(result, 0)} Lbs`
  }

  const sandDataValue = (sandType: string) => {
    if (sandData) {
      const sandDataByType = sandData.filter(sd => sd && sd.sandType === sandType)

      let maxWeight: number = 0
      let currentWeight: number = 0

      sandDataByType.forEach(sd => {
        maxWeight += (sd && sd.maxWeight) || 0
        currentWeight += (sd && sd.currentWeight) || 0
      })

      return `${roundUp(currentWeight, 0)}Lbs / ${roundUp(maxWeight, 0)}Lbs`
    }

    return `${0}Lbs / ${0}Lbs`
  }

  return (
    <TooltipPopup size={size}>
      <TooltipTable>
        <tbody>
          <tr>
            <TooltipTableHeader>Sand Type</TooltipTableHeader>
            <TooltipTableHeader>Weight when full</TooltipTableHeader>
          </tr>
          <tr>
            <TooltipTableColumn large>
              <SandIcon src={getSandTypeIcon(SandTypeEnum.typeUndefined)} />
              {SandTypeEnum.typeUndefined}
            </TooltipTableColumn>
            <TooltipTableColumn>
              {siloType ? siloTypeValue(SandTypeEnum.typeUndefined) : sandDataValue(SandTypeEnum.typeUndefined)}
            </TooltipTableColumn>
          </tr>
          <tr>
            <TooltipTableColumn large>
              <SandIcon src={getSandTypeIcon(SandTypeEnum.type2040)} />
              {SandTypeEnum.type2040}
            </TooltipTableColumn>
            <TooltipTableColumn>
              {siloType ? siloTypeValue(SandTypeEnum.type2040) : sandDataValue(SandTypeEnum.type2040)}
            </TooltipTableColumn>
          </tr>
          <tr>
            <TooltipTableColumn large>
              <SandIcon src={getSandTypeIcon(SandTypeEnum.type3050)} />
              {SandTypeEnum.type3050}
            </TooltipTableColumn>
            <TooltipTableColumn large>
              {siloType ? siloTypeValue(SandTypeEnum.type3050) : sandDataValue(SandTypeEnum.type3050)}
            </TooltipTableColumn>
          </tr>
          <tr>
            <TooltipTableColumn large>
              <SandIcon src={getSandTypeIcon(SandTypeEnum.type4070)} />
              {SandTypeEnum.type4070}
            </TooltipTableColumn>
            <TooltipTableColumn>
              {siloType ? siloTypeValue(SandTypeEnum.type4070) : sandDataValue(SandTypeEnum.type4070)}
            </TooltipTableColumn>
          </tr>
          <tr>
            <TooltipTableColumn large>
              <SandIcon src={getSandTypeIcon(SandTypeEnum.type100)} />
              {SandTypeEnum.type100}
            </TooltipTableColumn>
            <TooltipTableColumn>
              {siloType ? siloTypeValue(SandTypeEnum.type100) : sandDataValue(SandTypeEnum.type100)}
            </TooltipTableColumn>
          </tr>
        </tbody>
      </TooltipTable>
    </TooltipPopup>
  )
}

export default Tooltip
