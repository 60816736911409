import React, { useState, useEffect } from 'react'
import { SiloImageBackground, SiloImage, Bar } from './styles'

import SILO from '../../../../assets/images/silo.png'
import { DANGER, WARNING, SUCCESS } from '../../../../config/styles/colours'

interface AnimatedSiloProps {
  currentWeight: number
  maxCapacity: number
  siloThreshold: {
    amber: number
    red: number
  }
  reverse?: boolean
}

const AnimatedSilo = ({ currentWeight, maxCapacity, siloThreshold, reverse }: AnimatedSiloProps) => {
  const [percentage, setPercentage] = useState(reverse ? 0 : 100)

  useEffect(() => {
    const timeOut = setTimeout(() => {
      const calculatePercentage = Math.round((currentWeight / maxCapacity) * 100)
      setPercentage(calculatePercentage)
    }, 1000)
    return () => clearTimeout(timeOut)
  }, [currentWeight, maxCapacity])

  const getPercentageColor = () => {
    const { amber, red } = siloThreshold

    if (percentage <= red) return DANGER
    if (percentage <= amber) return WARNING
    return SUCCESS
  }

  return (
    <SiloImageBackground>
      <SiloImage src={SILO} />
      <Bar
        reverse={reverse}
        style={{
          backgroundColor: getPercentageColor(),
          height: `${reverse ? 100 - percentage : percentage || 0}%`,
        }}
      />
    </SiloImageBackground>
  )
}

export default AnimatedSilo
