import styled from 'styled-components/macro'
import { animated } from 'react-spring'
import { LIGHT_GREY } from '../../config/styles/colours'

export const SwitchStyled = styled(animated.div)`
  position: relative;
  display: flex;
  align-items: center;
  width: 50px;
  height: 25px;
  border-radius: 50px;
  border: 1px solid ${LIGHT_GREY};
  margin: 0 4px;
`

export const Controller = styled(animated.div)`
  width: 25px;
  height: 25px;
  border-radius: 50px;
  border: 1px solid ${LIGHT_GREY};
  position: absolute;
  background-color: white;
`
