import React from 'react'
import {
  CardContainer,
  Stats,
  Item,
  ItemContent,
  SandIcon,
  Graphs,
  Group,
  SandIconBox,
  SiloContainer,
  Cap,
  Percentage,
  CardHeader,
  Header,
} from './style'

import type100Icon from '../../assets/images/sandTypes/1.png'
import type2040Icon from '../../assets/images/sandTypes/2.png'
import type3050Icon from '../../assets/images/sandTypes/3.png'
import type4070Icon from '../../assets/images/sandTypes/4.png'
import defaultIcon from '../../assets/images/sandTypes/default.png'

import AnimatedSilo from './components/AnimatedSilo'
import GoogleMaps from '../GoogleMaps'
import { Silo, SandData, SandTypeEnum } from '../../config/types'

import { PRIMARY } from '../../config/styles/colours'
import Tooltip from '../Tooltip'
import { TooltipHover, TooltipIcon } from '../Tooltip/styles'
import { roundUp } from '../../config/utils/number'
import { DateGMTFormatter } from '../../config/utils/date'

interface CardProps extends React.HTMLAttributes<HTMLLIElement> {}

export const getSandTypeIcon = (type: string) => {
  switch (type) {
    case SandTypeEnum.type100:
      return type100Icon
    case SandTypeEnum.type3050:
      return type3050Icon
    case SandTypeEnum.type2040:
      return type2040Icon
    case SandTypeEnum.type4070:
      return type4070Icon
    default:
      return defaultIcon
  }
}

const Card = ({ children, ...otherProps }: CardProps) => {
  const { siloId, siloType, positionData, sandData, thresholdAmber, thresholdRed } = children as Silo
  const siloThreshold = { amber: thresholdAmber, red: thresholdRed }
  const locTime = sandData && sandData.locTime
  const currentWeight = sandData && sandData.currentWeight ? sandData.currentWeight : null
  const maxWeight = sandData && sandData.maxWeight ? sandData.maxWeight : null

  const getAvailableCapacity = () => {
    if (currentWeight && maxWeight) {
      return `${roundUp(maxWeight - currentWeight, 0)}Lbs`
    }
    return <span style={{ color: 'white' }}>No Data</span>
  }

  const getPercentage = (reverse?: boolean) => {
    if (currentWeight && maxWeight) {
      const result = Math.round((currentWeight / maxWeight) * 100)
      if (reverse) return `${100 - result}%`

      return `${result}%`
    }

    if (reverse) return '100%'
    return '0%'
  }

  const getMapCoords = () => {
    if (positionData) {
      const lat = positionData.latitude || 0
      const lng = positionData.longitude || 0
      return { lat, lng }
    }

    return { lat: 0, lng: 0 }
  }

  const getSandDataParam = (param: string) => {
    if (sandData) {
      const value = (sandData as SandData)[param]
      if (value) {
        if (param === 'currentWeight' || param === 'maxWeight') return `${roundUp(Number(value), 0)}Lbs`
        return value
      }
    }

    if (param === 'currentWeight') return '0Lbs'
    return 'No Data'
  }
  return (
    <CardContainer {...otherProps}>
      <CardHeader>
        <Header time={locTime}>{locTime ? `Last updated ${DateGMTFormatter(locTime)} ago` : 'empty'}</Header>
      </CardHeader>
      <Stats>
        <Group>
          <Item>
            <p>Silo ID</p>
            <ItemContent>{siloId}</ItemContent>
          </Item>

          <Item>
            <p>Model</p>
            <ItemContent>{siloType ? siloType.model : ''}</ItemContent>
          </Item>

          <Item>
            <p>Sand Type</p>
            <SandIconBox>
              {sandData && sandData.sandType && <SandIcon src={getSandTypeIcon(sandData.sandType)} />}
              <ItemContent>{getSandDataParam('sandType')}</ItemContent>
            </SandIconBox>
          </Item>
        </Group>
        <Group>
          <Item>
            <p>Blender #</p>
            <ItemContent>{getSandDataParam('blender')}</ItemContent>
          </Item>

          <Item>
            <TooltipHover margin>
              Weight when full <TooltipIcon size="15" color={PRIMARY} />{' '}
            </TooltipHover>
            <Tooltip siloType={siloType} />
            <ItemContent>{getSandDataParam('maxWeight')}</ItemContent>
          </Item>

          <Item>
            <p>Total Storage Vol</p>
            <ItemContent>{siloType ? `${roundUp(siloType.capacity, 0)}ft³` : ''}</ItemContent>
          </Item>
        </Group>
      </Stats>

      <Graphs>
        <SiloContainer>
          <AnimatedSilo currentWeight={currentWeight || 0} maxCapacity={maxWeight || 0} siloThreshold={siloThreshold} />
          <Cap>Current Inventory</Cap>
          <Percentage>{getSandDataParam('currentWeight')}</Percentage>
          <p>{getPercentage()}</p>
        </SiloContainer>
        <SiloContainer>
          <AnimatedSilo
            reverse
            currentWeight={currentWeight || 0}
            maxCapacity={maxWeight || 0}
            siloThreshold={siloThreshold}
          />
          <Cap>Available Capacity</Cap>
          <Percentage>{getAvailableCapacity()}</Percentage>
          <p>{getPercentage(true)}</p>
        </SiloContainer>
      </Graphs>

      <GoogleMaps coords={getMapCoords()} />
    </CardContainer>
  )
}

export default Card
