import { useState, useEffect } from 'react'
// import { useTransition } from 'react-spring'
import fetchFleets, { RetrieveAllFleets } from '../../services/fleets'
import { Fleet, Silo } from '../types'
import { MessageProps } from '../../components/Toast'

export const createStats = (fleets: Fleet[]) => {
  return fleets.map((fleet: Fleet) => {
    const { silos } = fleet

    let stats = {
      healthy: 0,
      warning: 0,
      critical: 0,
    }

    if (silos && silos.length > 0) {
      silos.forEach(({ sandData, thresholdRed, thresholdAmber }: Silo) => {
        const currentWeight = sandData && sandData.currentWeight && sandData.currentWeight
        const maxWeight = sandData && sandData.currentWeight && sandData.maxWeight

        if (currentWeight && maxWeight) {
          const percentage = Math.round((currentWeight / maxWeight) * 100)
          switch (true) {
            case percentage <= thresholdRed:
              stats = { ...stats, critical: stats.critical += 1 }
              break
            case percentage <= thresholdAmber:
              stats = { ...stats, warning: stats.warning += 1 }
              break
            default:
              stats = { ...stats, healthy: stats.healthy += 1 }
          }
        }
      })
    }

    return { ...fleet, stats }
  })
}

const useFleets = () => {
  const [toast, setToast] = useState<MessageProps>({ value: '' }) // Handle error message
  const [fleet, setFleet] = useState() // Fleet selected (Menu item)
  const [silos, setSilos] = useState<Silo[]>([]) // Silos selected
  const [lastRefresh, setLastRefresh] = useState(0)

  const [state, setState] = useState(() => {
    const fleets: Fleet[] = []

    return {
      fleets,
      loading: true,
    }
  })

  // TODO: Fix transitions animation on loading silos cards
  // const transitions = useTransition(silos, item => item && item.id, {
  //   from: { transform: 'translate3d(0,80px,0)', opacity: 0 },
  //   enter: { transform: 'translate3d(0,0px,0)', opacity: 1 },
  //   leave: { transform: 'translate3d(0,80px,0)', opacity: 0 },
  //   trail: 280,
  // })

  useEffect(() => {
    const getFleets = async () => {
      try {
        const response: RetrieveAllFleets = await fetchFleets()
        const time = new Date()

        let fleets = response.data.data.fleets

        // Create Silo's stats for each fleet
        if (fleets.length > 0) fleets = createStats(fleets)

        // Initialize default fleet and silos selected on first page load
        if (fleets[0]) {
          setFleet(fleets[0] as Fleet)
          const { silos } = fleets[0]
          if (silos) setSilos(silos)
        }

        setLastRefresh(time.getTime())
        setState({ fleets, loading: false })
      } catch (error) {
        const time = new Date()

        setToast({ value: error.message, type: 'error' })
        setLastRefresh(time.getTime())
        setState({ fleets: [], loading: false })
      }
    }

    getFleets()
  }, [])

  // TODO: add transitions in return
  return { ...state, lastRefresh, toast, fleet, silos, setToast, setFleet, setSilos, setLastRefresh }
}

export default useFleets
