import styled from 'styled-components/macro'
import { PALE_GREY, DANGER, DANGER_HOVER } from '../../config/styles/colours'
import { Z_INDEX } from '../../config/styles/constants'

interface Bind {
  left?: number
  top?: number
  width?: number
  height?: number
  ref?: any
}

export const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

export const HomeContainer = styled.div`
  display: flex;
  position: fixed;
`
export const HomeContent = styled.div<Bind>`
  display: flex;
  flex-direction: column;
  flex: 5;
  background-color: ${PALE_GREY};
  position: relative;
  z-index: ${Z_INDEX.high};
`
export const CardList = styled.ul`
  overflow-y: scroll;
  position: absolute;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-shrink: 1;
  flex-grow: 0;
  padding-top: 250px;
  align-self: center;
  z-index: ${Z_INDEX.low};
  height: 100%;
}
`

export const LogoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  > button {
    width: 100px;
    margin: 0 12px;
    &:last-of-type {
      background-color: ${DANGER};
      &:hover {
        background-color: ${DANGER_HOVER};
      }
    }
  }
`
