import styled from 'styled-components/macro'
import { Z_INDEX } from '../../config/styles/constants'

const PopupMask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${Z_INDEX.menu};
`

export default PopupMask
