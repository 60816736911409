import styled, { css } from 'styled-components/macro'
import { RESPONSIVE_BREAKPOINTS, Z_INDEX } from '../../config/styles/constants'
import { Menu, X } from 'react-feather'
import { LIGHT_GREY, PALE_SKY_BLUE, PRIMARY, PALE_GREY } from '../../config/styles/colours'
import Button from '../Button'

type FleetProps = {
  selected?: boolean
  empty?: boolean
}

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

export const BurgerMenu = styled(Menu)`
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: ${Z_INDEX.menu + 1};
  cursor: pointer;
  @media (min-width: ${RESPONSIVE_BREAKPOINTS.small}) {
    display: none;
  }
`
export const CloseMenu = styled(X)`
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: ${Z_INDEX.menu + 1};
  cursor: pointer;
  @media (min-width: ${RESPONSIVE_BREAKPOINTS.small}) {
    display: none;
  }
`
export const LogoContainer = styled.div`
  display: flex;
  height: 180px;
  border-bottom: 1px solid ${LIGHT_GREY};
  align-items: center;
  justify-content: center;
`
export const SideLogo = styled.img`
  width: 75%;
  object-fit: contain;
`
export const FleetList = styled.div<FleetProps>`
  height: ${({ empty }) => (empty ? '100%' : 'auto')};
  overflow-y: auto;
  width: 100%;
`

export const FleetTile = styled.h2`
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 600;
  text-align: left;
`
export const FleetSubtitle = styled.p`
  font-size: 11px;
  margin: 0;
  text-align: left;
`

const Selected = css`
  background-color: ${PALE_GREY};
  ${FleetTile} {
    color: ${PRIMARY};
  }
  ${FleetSubtitle} {
    color: ${PRIMARY};
  }
`
export const SilosQuantity = styled.p`
  margin: 0;
  background-color: ${PALE_SKY_BLUE};
  padding: 5px 10px;
  border-radius: 30px;
  color: ${PRIMARY};
  width: 88px;
  margin-left: 5px;
`
export const PhoneNav = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${RESPONSIVE_BREAKPOINTS.small}) {
    display: none;
  }
`
export const SideBarButton = styled(Button)`
  padding: 20px 0;
  width: 100%;
  border-bottom: 1px solid ${LIGHT_GREY};
  border-top: 1px solid ${LIGHT_GREY};
  &:last-of-type {
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
  }
`

export const SideBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  flex: 1;
  min-width: 230px;
  max-width: 230px;
  transition: all 0.6s ease-in-out;
  margin-left: 0;
  position: absolute;
  height: 100%;
  z-index: ${Z_INDEX.menu};
  border-right: 1px solid ${LIGHT_GREY};

  @media (min-width: ${RESPONSIVE_BREAKPOINTS.small}) {
    margin-left: 0 !important;
    position: relative;
  }

  > ${LogoContainer} {
    flex: 0 0 180px;
  }

  > ${FleetList} {
    flex: 1 1 auto;
  }
`

export const FleetMenu = styled(Button)<FleetProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  width: 100%;
  border-bottom: 1px solid ${LIGHT_GREY};
  ${({ selected }) => selected && Selected}

  > ${SilosQuantity} {
    flex: 0 0 65px;
  }
`
