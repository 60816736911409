import React from 'react'
import { Router, Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom'

import NotFound from '../screens/NotFound'
import Home from '../screens/Home'
import Login from '../screens/Login'
import Toast from '../components/Toast'
import history from '../config/router'
import useAuth from '../config/hooks/useAuth'
import userContext from '../config/hooks/userContext'
import Policies from '../screens/Policies'

const Routes = () => {
  const { loading, user, toast, setToast } = useAuth()

  return (
    <userContext.Provider
      value={{
        user,
        loading,
      }}
    >
      <Router history={history}>
        <Toast message={toast} action={setToast} />
        <Switch>
          <Route exact path="/" render={() => (user ? <Redirect to="/home" /> : <Login initializing={loading} />)} />

          {user && <Route path="/home" component={Home} />}

          <Route
            path="/policies/:doc"
            component={({ location: { pathname } }: RouteComponentProps) => <Policies {...{ pathname }} />}
          />

          {!loading && <Route component={NotFound} />}
        </Switch>
      </Router>
    </userContext.Provider>
  )
}

export default Routes
