import styled from 'styled-components/macro'
import { LIGHT_GREY, PRIMARY } from '../../config/styles/colours'
import Button from '../Button'
import { RESPONSIVE_BREAKPOINTS, Z_INDEX } from '../../config/styles/constants'

export const NavBarContainer = styled.div`
  background-color: white;
  position: relative;
  z-index: ${Z_INDEX.medium};
  height: 100px;
  width: calc(100% - 15px);
  border-bottom: 1px solid ${LIGHT_GREY};
`
export const NavBarList = styled.ul`
  display: flex;
  justify-content: flex-end;
  height: 100%;
  @media (max-width: ${RESPONSIVE_BREAKPOINTS.small}) {
    display: none;
  }
`
export const Li = styled.li`
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 15px;
`

export const NavButton = styled(Button)`
  height: 100%;
  &:hover {
    color: ${PRIMARY};
  }
`
