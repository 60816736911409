import styled from 'styled-components/macro'
import { Z_INDEX, RESPONSIVE_BREAKPOINTS } from '../../config/styles/constants'
import { COOL_GREY } from '../../config/styles/colours'
import { Info } from 'react-feather'

type ColumnType = {
  large?: boolean
}

export type PopupSize = 'small' | 'medium' | 'large' | undefined

const handlePopupSzie = (size?: PopupSize) => {
  switch (size) {
    case 'small':
      return '120px'
    case 'medium':
      return '220px'
    case 'large':
      return '330px'
    default:
      return 'auto'
  }
}
export const TooltipPopup = styled.div<{ size?: PopupSize }>`
  width: ${({ size }) => handlePopupSzie(size)};

  margin-left: 80px;
  margin-top: -15px;

  display: none;
  z-index: ${Z_INDEX.modal};

  position: absolute;
  background: transparent;

  font-size: 13px;
  transition: opacity 0.8s ease-out;
`

export const TooltipHover = styled.p<{ margin?: boolean }>`
  display: flex;
  justify-content: flex-start;

  @media (max-width: ${RESPONSIVE_BREAKPOINTS.small}) {
    justify-content: center;
  }

  margin-bottom: ${({ margin }) => (margin ? '10px' : 0)};

  cursor: default;

  &:hover + ${TooltipPopup} {
    display: block;
  }
`

export const TooltipIcon = styled(Info)`
  margin-right: 5px;
  margin-left: 5px;
`

export const TooltipTable = styled.table`
  width: 100%;

  border-radius: 6px;
  box-shadow: 3px 3px 14px 0px rgba(0, 0, 0, 0.4);

  padding: 5px;
  background: white;
`

export const TooltipTableColumn = styled.td`
  width: ${({ large }: ColumnType) => (large ? '110px' : 'auto')};
  color: black;
`

export const TooltipTableHeader = styled.th`
  color: ${COOL_GREY};
`
