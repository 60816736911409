import styled, { css } from 'styled-components/macro'
import { PRIMARY, PRIMARY_HOVER, COOL_GREY } from '../../config/styles/colours'
import { BORDER_RADIUS } from '../../config/styles/constants'

type ButtonStyledProps = {
  simple?: boolean
  noFocus?: boolean
}

const Classic = css<ButtonStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${PRIMARY};
  color: white;
  transition: 0.3s all ease-in-out;
  border-radius: ${BORDER_RADIUS}px;
  height: 100%;
  margin-bottom: 12px;
  height: 35px;
  &:hover {
    background-color: ${PRIMARY_HOVER};
    border: 1px solid ${({ noFocus }) => (noFocus ? 'transparent' : PRIMARY)};
  }
`

export const ButtonStyled = styled.button<ButtonStyledProps>`
  cursor: pointer;
  background-color: transparent;
  color: ${COOL_GREY};
  outline: 0;
  border: 1px solid transparent;
  ${({ simple }) => !simple && Classic}

  &:focus {
    border: 1px solid ${({ noFocus }) => (noFocus ? 'transparent' : PRIMARY_HOVER)} !important;
  }

  &:active {
    border: 1px solid transparent;
  }
`
