import styled, { keyframes } from 'styled-components/macro'
import { PRIMARY, DARK_SKY_BLUE } from '../../config/styles/colours'
import { Z_INDEX } from '../../config/styles/constants'

interface MapProps {
  lat: number
  lng: number
}

export const MapsContainer = styled.div`
  width: 100%;
  height: 200px;
`
export const MarkerContainer = styled.div<MapProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 100%;
`
export const CustomMarker = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${DARK_SKY_BLUE};
  border-radius: 100%;
  z-index: ${Z_INDEX.low};
`

const growAnimation = keyframes`
0% {
  width: 15px;
  height: 15px;
  opacity: 1;
}

20% {
  width: 70px;
  height: 70px;
  opacity: 0;
}

100% {
  width: 70px;
  height: 70px;
  opacity: 0;
}
`

export const AnimatedMarker = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: ${PRIMARY};
  border-radius: 100%;
  opacity: 1;
  animation: ${growAnimation} 4s linear infinite;
`

export const mapStyles: any = [
  {
    featureType: 'landscape.natural',
    elementType: 'geometry.fill',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#e0efef',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry.fill',
    stylers: [
      {
        visibility: 'on',
      },
      {
        hue: '#1900ff',
      },
      {
        color: '#c0e8e8',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        lightness: 100,
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'on',
      },
      {
        lightness: 700,
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#7dcdcd',
      },
    ],
  },
]
