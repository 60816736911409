import debounce from 'lodash.debounce'
import React, { useRef, useState, useEffect } from 'react'
import { MetroSpinner } from 'react-spinners-kit'
import Input from '../../../../components/Input'
import Switch from '../../../../components/Switch'
import Toast, { MessageProps } from '../../../../components/Toast'
import { PRIMARY } from '../../../../config/styles/colours'
import { User } from '../../../../config/types'
import { sendContactEmail } from '../../../../services/emails'
import { updateCurrentUser } from '../../../../services/user'
import { CallUs, EnableEmail, LoadingContainer, SendButton, SubText, SwitchContainer, ManualEntryLink } from './styles'

type ContactProps = {
  toast: MessageProps
  setToast: React.Dispatch<React.SetStateAction<MessageProps>>
  setDataState: React.Dispatch<
    React.SetStateAction<{
      currentUser: User | null
      isLoading: boolean
    }>
  >
  currentUser: User | null
  isLoading: boolean
}

const Contact = ({ toast, setToast, currentUser, setDataState, isLoading: isLoadingUser }: ContactProps) => {
  const defaultToggleValue = currentUser ? currentUser.areEmailNotificationsActivated : false
  const externalLink = currentUser
    ? `https://siloresources.z19.web.core.windows.net/?UserID=${currentUser.id}`
    : undefined
  const [isEmailOn, setIsEmailOn] = useState(defaultToggleValue)
  const [isSendingEmail, setIsSendingEmail] = useState(false)
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')

  // Element to focus on when contact open (improved accessibility!!!)
  const focusElement = useRef<HTMLTextAreaElement>(null!)
  useEffect(() => {
    if (focusElement && focusElement.current) focusElement.current.focus()
  }, [focusElement])

  // Debounced function to update the user's preferences
  const updatePreferences = useRef(
    debounce(async (areEmailNotificationsActivated: boolean) => {
      try {
        // Update user's preferences
        const updatedUser = await updateCurrentUser({ areEmailNotificationsActivated })

        // Once updated, update the user state with new user data
        setDataState((currentState): { currentUser: User | null; isLoading: boolean } => ({
          ...currentState,
          currentUser: updatedUser,
        }))
      } catch (error) {
        // If failed, reset the toggle
        setIsEmailOn(defaultToggleValue)
        if (error.message) {
          setToast({ type: 'error', value: error.message })
        }
      }
    }, 1000),
  ).current

  const handleToggle = () => {
    setIsEmailOn(!isEmailOn)
    updatePreferences(!isEmailOn)
  }

  const handleSend = async () => {
    setError('')

    if (!message) {
      setError('Message cannot be empty.')
      return
    }

    setIsSendingEmail(true)

    try {
      await sendContactEmail(message)
      setMessage('')
      setToast({ value: 'Email sent successfully! We will be in touch shortly.', type: 'success' })
    } catch (error) {
      if (error.message) {
        setToast({ type: 'error', value: error.message })
      }
    }

    setIsSendingEmail(false)
  }

  if (isLoadingUser) {
    return (
      <LoadingContainer>
        <MetroSpinner color={PRIMARY} size={50} />
      </LoadingContainer>
    )
  }

  return (
    <>
      <Toast message={toast} action={setToast} />
      <Input
        ref={focusElement}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)}
        error={error}
        label="Your Message"
        placeholder="Your message..."
        textArea
        value={message}
        disabled={isSendingEmail}
      />

      <SendButton onClick={handleSend} loading={isSendingEmail} disabled={isSendingEmail}>
        Send
      </SendButton>

      {externalLink && (
        <ManualEntryLink>
          <a target="_blank" href={externalLink} rel="noopener noreferrer">
            Manual Entry
          </a>
        </ManualEntryLink>
      )}

      <CallUs>Call us Toll Free on (888) 360-SAND</CallUs>
      <SwitchContainer>
        <EnableEmail>Enable Email Notifications</EnableEmail>
        <Switch onClick={handleToggle} status={isEmailOn} />
      </SwitchContainer>
      <SubText>Would you like to receive notifications if a silo status changes?</SubText>
    </>
  )
}

export default Contact
