import React from 'react'
import { NavBarContainer, NavBarList, Li, NavButton } from './styles'

interface NavBarProps extends React.HTMLAttributes<HTMLDivElement> {
  showContact: React.SetStateAction<any>
  showLogout: React.SetStateAction<any>
}

const NavBar = ({ showContact, showLogout, ...otherProps }: NavBarProps) => {
  return (
    <NavBarContainer {...otherProps}>
      <NavBarList>
        <Li>
          <NavButton simple onClick={showContact}>
            Contact
          </NavButton>
        </Li>
        <Li>
          <NavButton simple onClick={showLogout}>
            Logout
          </NavButton>
        </Li>
      </NavBarList>
    </NavBarContainer>
  )
}

export default NavBar
