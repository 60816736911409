import styled from 'styled-components/macro'
import { RESPONSIVE_BREAKPOINTS } from '../../config/styles/constants'
import { Link } from 'react-router-dom'
import { PRIMARY } from '../../config/styles/colours'

export const SiloImageSection = styled.div`
  @media (max-width: ${RESPONSIVE_BREAKPOINTS.small}) {
    display: none;
  }
`

export const SiloImage = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`
export const LoginSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  max-width: 260px;
  text-align: center;
`

export const Logo = styled.img`
  width: 130px;
  margin-bottom: 24px;
`

export const Title = styled.h1`
  margin-bottom: 24px;
  text-align: center;
`

export const SubTitle = styled.p`
  margin-bottom: 24px;
  text-align: center;
`
export const CheckEmail = styled.p`
  font-size: 11px;
`

export const LoginContainer = styled.div`
  display: flex;
  height: 100vh;

  > ${SiloImageSection}, > ${LoginSection} {
    flex: 1 1 50%;
  }
`

export const TermsConditionsTitle = styled.h5`
  margin-bottom: 12px;
`

export const TermsConditionsLink = styled(Link)`
  color: ${PRIMARY};
`
