import styled from 'styled-components/macro'
import Button from '../../../../components/Button'
import { LIGHT_GREY, PRIMARY_HOVER, PRIMARY } from '../../../../config/styles/colours'
import { BORDER_RADIUS } from '../../../../config/styles/constants'

export const SendButton = styled(Button)`
  width: 50%;
`

export const ManualEntryLink = styled.div<{ noFocus?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  background-color: transparent;
  transition: 0.3s all ease-in-out;
  border: 1px solid ${PRIMARY};
  border-radius: ${BORDER_RADIUS}px;
  height: 100%;
  margin-bottom: 12px;
  height: 35px;

  cursor: pointer;

  &:hover {
    border: 1px solid ${PRIMARY_HOVER} !important;
    background: ${PRIMARY_HOVER} !important;

    a {
      color: white;
    }
  }

  &:active {
    border: 1px solid transparent;
  }

  a {
    color: ${PRIMARY};
    text-decoration: none;
    transition: 0.3s all ease-in-out;

    cursor: pointer;
  }
`

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${LIGHT_GREY};
  padding-bottom: 12px;
  width: 60%;
  min-width: 235px;
`
export const CallUs = styled.p`
  margin: 20px 0;
`
export const EnableEmail = styled.p`
  margin: 0;
`
export const SubText = styled.p`
  margin-top: 12px;
`

export const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 400px;
`
