export const BORDER_RADIUS = 3
export const RESPONSIVE_BREAKPOINTS = {
  small: '768px',
  medium: '1000px',
}
export const Z_INDEX = {
  lowest: 0,
  low: 1,
  medium: 5,
  high: 10,
  menu: 15,
  modal: 20,
}
