import styled from 'styled-components/macro'
import { DANGER } from '../../../../config/styles/colours'
import { Z_INDEX } from '../../../../config/styles/constants'

type BarProps = {
  reverse?: boolean
}

export const SiloImageBackground = styled.div`
  position: relative;
  width: 23px;
  height: 65px;
`
export const SiloImage = styled.img`
  position: absolute;
  z-index: ${Z_INDEX.medium};
  width: 23px;
  height: 65px;
`

export const Bar = styled.div<BarProps>`
  position: absolute;
  background-color: ${DANGER};
  width: 100%;
  height: 0%;
  z-index: ${Z_INDEX.low};
  transition: all 0.5s ease-in-out;
  ${({ reverse }) =>
    reverse
      ? {
          top: 0,
        }
      : {
          bottom: 0,
        }}
`
