import axios from 'axios'

import { CHECK_USER_EXISTS_API, CURRENT_USER_API } from './endpoints'
import { firebaseUser } from './firebase/firebaseAuth'
import { User } from '../config/types'

export type UserExists = {
  email: string
  role: string
}

export const fetchUserExists = async (userEmail: string) => {
  const result = await axios.get(CHECK_USER_EXISTS_API, { params: { email: userEmail } })
  if (result.data.data) return result.data.data as UserExists
  throw new Error("This user doesn't exist. Please contact an admin to create an account.")
}

export const fetchCurrentUser = async (): Promise<User> => {
  const currentUser = firebaseUser()
  if (!currentUser) {
    throw new Error('No user logged in found, not sending email')
  }

  try {
    const token = await currentUser.getIdToken()
    const response = await axios.get(CURRENT_USER_API, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const user = response.data.data as User
    return user
  } catch (error) {
    console.error(error)
    throw new Error('Could not retrieve the current user, please try again later.')
  }
}

export const updateCurrentUser = async ({
  areEmailNotificationsActivated,
}: {
  areEmailNotificationsActivated?: boolean
}): Promise<User> => {
  const currentUser = firebaseUser()
  if (!currentUser) {
    throw new Error('No user logged in found, not sending email')
  }

  try {
    const token = await currentUser.getIdToken()
    const response = await axios.post(
      CURRENT_USER_API,
      {
        areEmailNotificationsActivated,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    const updatedUser = response.data.data as User
    return updatedUser
  } catch (error) {
    console.error(error)
    throw new Error('Could not update the current user, please try again later.')
  }
}
